import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";

import {
  FormModal,
  FinalFormField,
  Button,
  ButtonStyles,
  IconButton,
  IconButtonStyles,
  LockIcon,
  LockUnlockedIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  useTooltip,
} from "yuka";

import { useWrite, QUERY_KEYS } from "api";
import { DEAL_FILE_STATUS_INFORMATION_REQUESTED, DEAL_FILE_STATUS_APPROVED } from "utils/constants";

const StyledSpan = styled.span`
  text-wrap: nowrap;
`;

const ConnectionActions = ({ connection }) => {
  const [showModal, setShowModal] = React.useState(false);
  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["deal_file_review"])
  );

  const approveDealFile = () => {
    if (confirm("Are you sure you want to approve this deal file?")) {
      onSubmit({
        tradeId: connection.tradeId,
        apiType: connection.apiType,
        dealFileStatus: DEAL_FILE_STATUS_APPROVED,
      });
    }
  };

  const submitRequestChanges = data => {
    return onSubmit({
      tradeId: connection.tradeId,
      apiType: connection.apiType,
      dealFileStatus: DEAL_FILE_STATUS_INFORMATION_REQUESTED,
      ...data,
    }).then(() => setShowModal(false));
  };

  const lockRef = React.useRef(null);
  const tooltip = useTooltip(
    lockRef,
    <StyledSpan>
      Deal file is currently {connection.isDealFileLocked ? "locked" : "unlocked"}
    </StyledSpan>
  );

  const toggleDealFileLock = () => {
    if (
      confirm(
        `Are you sure you want to ${
          connection.isDealFileLocked ? "unlock" : "lock"
        } this deal file?`
      )
    ) {
      onSubmit({
        tradeId: connection.tradeId,
        apiType: connection.apiType,
        isDealFileLocked: !connection.isDealFileLocked,
      });
    }
  };

  if (connection.dealFileStatus === DEAL_FILE_STATUS_APPROVED) {
    return (
      <>
        <IconButton
          ref={lockRef}
          buttonStyle={IconButtonStyles.KEY_ACTION}
          onClick={toggleDealFileLock}
          icon={connection.isDealFileLocked ? LockIcon : LockUnlockedIcon}
        />
        {tooltip}
      </>
    );
  }

  return (
    <div>
      <Button
        leadingIcon={ThumbsUpIcon}
        buttonStyle={ButtonStyles.RAISED}
        onClick={approveDealFile}
      >
        Approve Deal File
      </Button>
      <Button
        leadingIcon={ThumbsDownIcon}
        buttonStyle={ButtonStyles.RAISED}
        g
        onClick={() => setShowModal(true)}
      >
        Request More Information
      </Button>
      {showModal && (
        <Form onSubmit={submitRequestChanges}>
          {({ handleSubmit }) => (
            <FormModal
              onClose={() => setShowModal(false)}
              onSubmit={handleSubmit}
              title="Request more Information"
            >
              <FinalFormField type="textarea" name="complianceNotes" />
            </FormModal>
          )}
        </Form>
      )}
    </div>
  );
};

ConnectionActions.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    phase: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
    apiType: PropTypes.string,
    dealFileStatus: PropTypes.string,
  }).isRequired,
};

export default ConnectionActions;
