import { Table, Card, CardStyles } from "yuka";

import { useInfiniteFetch, QUERY_KEYS } from "api";
import RelationshipsContext from "utils/RelationshipsContext";
import { documentColumns } from "ExecutionApp/TaskView/columns";

const ComplianceTasksTableView = () => {
  const {
    data: documents,
    fetchNextPage: fetchNextDocumentsPage,
    isFetchingNextPage: isFetchingNextDocumentsPage,
    isLoading: isLoadingDocuments,
  } = useInfiniteFetch(QUERY_KEYS.EXPIRING_DOCUMENTS.list());

  return (
    <Card cardStyle={CardStyles.SECTIONED} title="Expiring Client Documents">
      <Table
        data={documents}
        columns={documentColumns}
        usePercentageColumnWidths
        emptyTablePlaceholder="No expired or soon to be expired documents"
        paginationFunc={fetchNextDocumentsPage}
        isPaginationLoading={isFetchingNextDocumentsPage}
        isLoading={isLoadingDocuments}
      />
    </Card>
  );
};

export default ComplianceTasksTableView;
export { RelationshipsContext };
