import { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { fetchQuery, getCachedById, QUERY_KEYS } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { Input, Select } from "forms/FormComponents";
import { GlobalContext } from "utils/StateProvider";

const StyledFilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0 16px;
`;

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const ComplianceConnectionsTableFilters = ({ name, isLoading }) => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const selectedSource = forms[name]?.source || null;
  const sources = [];
  if (selectedSource) {
    const cachedSourceObj = getCachedById(QUERY_KEYS.SOURCES, selectedSource, "searchId");
    sources.push(cachedSourceObj);
  }

  const loadSources = useCallback(
    search =>
      fetchQuery(QUERY_KEYS.SOURCES.list({ "filter[search]": search })).then(sources =>
        sources.map(source => ({
          label: `${source.name} (${source.category}) #${source.searchId}`,
          value: source.searchId,
        }))
      ),
    []
  );

  return (
    <StyledFilterWrapper>
      <Input name="search" placeholder="Trade ID or Company Name" width={240} />
      <Select
        placeholder="Source or User"
        name="source"
        options={sources.map(source => ({
          label: `${source.name} (${source.category}) #${source.searchId}`,
          value: source.searchId,
        }))}
        loadOptions={loadSources}
        width={240}
      />
      {isLoading && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
    </StyledFilterWrapper>
  );
};

ComplianceConnectionsTableFilters.propTypes = {
  name: PropTypes.string.isRequired,
  phaseOptions: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

ComplianceConnectionsTableFilters.defaultProps = {
  isLoading: false,
};

export default ComplianceConnectionsTableFilters;
