import { GlobalNavigation, NavigationCategoryHeader } from "yuka";

import ZXLogo from "utils/ZXLogo";
import OneTapPrompt from "templates/OneTapPrompt";

import NavLink from "./NavLink";

const Navigation = () => (
  <GlobalNavigation logo={<ZXLogo />}>
    <NavigationCategoryHeader>Connections</NavigationCategoryHeader>
    <NavLink to="/connections/review/" label="Open Deal Files" mpHeader="Connections" />
    <NavLink to="/connections/approved/" label="Approved Deal Files" mpHeader="Connections" />
    <NavLink to="/clients/" label="People (KYC)" />
    <NavLink to="/expiringDocuments/" label="Expiring Documents" />
    <OneTapPrompt />
  </GlobalNavigation>
);

export default Navigation;
