import {
  DEAL_FILE_STATUS_NEEDS_REVIEW,
  DEAL_FILE_STATUS_INFORMATION_UPDATED,
  DEAL_FILE_STATUS_INFORMATION_REQUESTED,
  DEAL_FILE_STATUS_APPROVED,
} from "utils/constants";

const REVIEW_FILTER_NAME = "reviewFilter";
const APPROVED_FILTER_NAME = "approvedFilter";

const dealFileStatusQueryParams = {
  [REVIEW_FILTER_NAME]: [
    DEAL_FILE_STATUS_NEEDS_REVIEW,
    DEAL_FILE_STATUS_INFORMATION_UPDATED,
    DEAL_FILE_STATUS_INFORMATION_REQUESTED,
  ],
  [APPROVED_FILTER_NAME]: DEAL_FILE_STATUS_APPROVED,
};

export { REVIEW_FILTER_NAME, APPROVED_FILTER_NAME, dealFileStatusQueryParams };
