import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Fonts, ComponentGroup, Thumbnail, HyperLink, LinkTypes, LinkStyles } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import { MixpanelSourceContext } from "utils/mixpanel";
import { ZXLink } from "utils/routing";

import { FrontendError, Loading } from "templates/ErrorPages";
import DealFile from "templates/DealFile";
import { useDispatch, ACTIONS } from "utils/StateProvider";
import { ConnectionComplianceStatusCard } from "utils/connections";
import RelationshipsContext from "utils/RelationshipsContext";

import ComplianceActionItems from "./ComplianceActionItems";
import ConnectionActions from "./ConnectionActions";
import TradeDetailsCard from "./TradeDetailsCard";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 12px;
  align-items: center;
  > :last-child {
    margin-left: auto;
  }
`;

const StyledProfileContent = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`;

const ConnectionProfileView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, isLoadingError, data, relationships } = useFetch(
    QUERY_KEYS.CONNECTIONS.detail(id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isLoadingError) {
    return <FrontendError />;
  }

  const connection = data;
  const company = getRelatedObj(connection.company, relationships);

  // Copy trade ID to clipboard
  const handleClickTradeId = () => {
    // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(connection.tradeId);
        dispatch({
          type: ACTIONS.addToast,
          message: `Copied "${connection.tradeId}" to clipboard`,
        });
      }
    });
  };

  return (
    <MixpanelSourceContext.Provider value="connection profile">
      <RelationshipsContext.Provider value={relationships}>
        <Fonts.Caption2theme50>
          <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
            Connections
          </HyperLink>
          <span>
            {" "}
            / {company.name} ({connection.tradeId})
          </span>
        </Fonts.Caption2theme50>
        <StyledHeader>
          <Thumbnail size="48px" src={company.thumbnail} />
          <Fonts.Headline1theme100>
            <HyperLink onClick={handleClickTradeId} linkStyle={LinkStyles.INVISIBLE}>
              {connection.tradeId}
            </HyperLink>
            &nbsp;(
            <ZXLink
              component={HyperLink}
              linkStyle={LinkStyles.INVISIBLE}
              url={company.zxUrl}
              profileType="company"
            >
              {company.legalName || company.name}
            </ZXLink>
            )
          </Fonts.Headline1theme100>
          <ConnectionActions connection={connection} />
        </StyledHeader>
        <StyledProfileContent>
          <ComponentGroup>
            {connection.complianceNotes && (
              <ConnectionComplianceStatusCard connection={connection} />
            )}
            <ComplianceActionItems connection={connection} />
            <TradeDetailsCard connection={connection} />
          </ComponentGroup>
          <DealFile connection={connection} showComplianceDocs />
        </StyledProfileContent>
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

export default ConnectionProfileView;
