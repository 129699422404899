import { uniqBy } from "lodash-es";

const BROKER_TYPE_OPTIONS = [
  { label: "STA", value: "STA" },
  { label: "STN", value: "STN" },
  { label: "SFA", value: "SFA" },
  { label: "Ancillary Closing Document", value: "Ancillary Closing Document" },
  { label: "AML Letter to Zanbato", value: "AML Letter to Zanbato" },
  { label: "Zanbato's AML Letter to BD", value: "Zanbato's AML Letter to BD" },
  { label: "Compliance Public Review", value: "Compliance Public Review" },
  { label: "KYC - Other", value: "KYC - Other" },
  { label: "W-8", value: "W-8" },
  { label: "W-9", value: "W-9" },
  { label: "BD Fee Share", value: "BD Fee Share" },
  { label: "NDA or Non-Circ Agreement", value: "NDA or Non-Circ Agreement" },
  { label: "PRAA (Rate Card)", value: "PRAA (Rate Card)" },
  { label: "SFA Addendum - Overarching", value: "SFA Addendum - Overarching" },
  { label: "SFA Addendum - Deal Specific", value: "SFA Addendum - Deal Specific" },
  { label: "Special Fee Arrangement", value: "Special Fee Arrangement" },
  { label: "Fee Agreement - Other", value: "Fee Agreement - Other" },
  { label: "Subscription Agreement", value: "Subscription Agreement" },
  { label: "ATS Rulebook", value: "ATS Rulebook" },
  { label: "Onboarding Document - Other", value: "Onboarding Document - Other" },
  { label: "Invoice Template", value: "Invoice Template" },
  { label: "Invoice", value: "Invoice" },
  { label: "Other", value: "Other" },
];

const CLIENT_TYPE_OPTIONS = [
  { label: "STA", value: "STA" },
  { label: "STN", value: "STN" },
  { label: "SFA", value: "SFA" },
  { label: "Ancillary Closing Document", value: "Ancillary Closing Document" },
  { label: "Buyer Fee Agreement", value: "Buyer Fee Agreement" },
  { label: "Seller Fee Agreement", value: "Seller Fee Agreement" },
  { label: "Firm Buy Order", value: "Firm Buy Order" },
  { label: "Firm Sell Order", value: "Firm Sell Order" },
  { label: "Buyer Fee Agreement - Forward", value: "Buyer Fee Agreement - Forward" },
  { label: "Seller Fee Agreement - Forward", value: "Seller Fee Agreement - Forward" },
  { label: "SFA Addendum", value: "SFA Addendum" },
  { label: "Fee Agreement - Other", value: "Fee Agreement - Other" },
  {
    label: "Buyer confidential Questionnaire (Individual)",
    value: "Buyer confidential Questionnaire (Individual)",
  },
  {
    label: "Seller confidential Questionnaire (Individual)",
    value: "Seller confidential Questionnaire (Individual)",
  },
  {
    label: "Buyer confidential Questionnaire (Entity)",
    value: "Buyer confidential Questionnaire (Entity)",
  },
  {
    label: "Seller confidential Questionnaire (Entity)",
    value: "Seller confidential Questionnaire (Entity)",
  },
  {
    label: "Buyer confidential Questionnaire (Grantor Trust)",
    value: "Buyer confidential Questionnaire (Grantor Trust)",
  },
  {
    label: "Seller confidential Questionnaire (Grantor Trust)",
    value: "Seller confidential Questionnaire (Grantor Trust)",
  },
  { label: "W-8", value: "W-8" },
  { label: "W-9", value: "W-9" },
  { label: "ID Scan", value: "ID Scan" },
  { label: "Compliance Public Review", value: "Compliance Public Review" },
  { label: "Entity Certificate of Formation", value: "Entity Certificate of Formation" },
  { label: "Entity Operating Agreement", value: "Entity Operating Agreement" },
  { label: "Entity Certificate of Good Standing", value: "Entity Certificate of Good Standing" },
  { label: "Entity TIN", value: "Entity TIN" },
  { label: "Trust Formation Document", value: "Trust Formation Document" },
  { label: "Trust List of Beneficiaries", value: "Trust List of Beneficiaries" },
  { label: "KYC - Other", value: "KYC - Other" },
  { label: "Invoice", value: "Invoice" },
  { label: "Invoice Template", value: "Invoice Template" },
  { label: "Wire Instructions", value: "Client Wire Instructions" },
  { label: "Share Ownership Document", value: "Share Ownership Document" },
  { label: "Other", value: "Other" },
];

const SPV_TYPE_OPTIONS = [
  { label: "Master Operating Agreement", value: "Master Operating Agreement" },
  { label: "Confidential Information Packet", value: "Confidential Information Packet" },
  { label: "Series Schedule", value: "Series Schedule" },
  { label: "Wire Instructions", value: "SPV Wire Instructions" },
  { label: "SPV Side Letter", value: "SPV Side Letter" },
  { label: "SPV Document Other", value: "SPV Document Other" },
];

const SPV_CLASS_OPTIONS = [
  { label: "Subscription Agreement", value: "SPV Subscription Agreement" },
  { label: "Class Supplement", value: "Class Supplement" },
  { label: "Key Terms", value: "Key Terms" },
  { label: "SPV Side Letter", value: "SPV Side Letter" },
  { label: "SPV Document Other", value: "SPV Document Other" },
];

const SPV_INVESTMENT_OPTIONS = [
  {
    label: "Buyer confidential Questionnaire (Individual)",
    value: "Buyer confidential Questionnaire (Individual)",
  },
  {
    label: "Buyer confidential Questionnaire (Entity)",
    value: "Buyer confidential Questionnaire (Entity)",
  },
  {
    label: "Buyer confidential Questionnaire (Grantor Trust)",
    value: "Buyer confidential Questionnaire (Grantor Trust)",
  },
  { label: "W-8", value: "W-8" },
  { label: "W-9", value: "W-9" },
  { label: "ID Scan", value: "ID Scan" },
  { label: "Compliance Public Review", value: "Compliance Public Review" },
  { label: "Entity Certificate of Formation", value: "Entity Certificate of Formation" },
  { label: "Entity Operating Agreement", value: "Entity Operating Agreement" },
  { label: "Entity Certificate of Good Standing", value: "Entity Certificate of Good Standing" },
  { label: "Entity TIN", value: "Entity TIN" },
  { label: "Trust Formation Document", value: "Trust Formation Document" },
  { label: "Trust List of Beneficiaries", value: "Trust List of Beneficiaries" },
  { label: "KYC - Other", value: "KYC - Other" },
  { label: "Subscription Agreement", value: "SPV Subscription Agreement" },
  { label: "SPV Side Letter", value: "SPV Side Letter" },
  { label: "SPV Document Other", value: "SPV Document Other" },
];

// When uploading here, we don't have context of broker or client, just show all
const CONNECTION_OPTIONS = uniqBy([...CLIENT_TYPE_OPTIONS, ...BROKER_TYPE_OPTIONS], "value");

const BROKER = "Broker";
const CLIENT = "Client";
const SPV = "SPV";
const SPV_CLASS = "SPV Class";
const SPV_INVESTMENT = "SPV Investment";
const CONNECTION = "Connection";

const DOCUMENT_TYPE_OPTIONS_MAP = {
  [BROKER]: BROKER_TYPE_OPTIONS,
  [CLIENT]: CLIENT_TYPE_OPTIONS,
  [SPV]: SPV_TYPE_OPTIONS,
  [SPV_CLASS]: SPV_CLASS_OPTIONS,
  [SPV_INVESTMENT]: SPV_INVESTMENT_OPTIONS,
  [CONNECTION]: CONNECTION_OPTIONS,
};

export {
  BROKER_TYPE_OPTIONS,
  CLIENT_TYPE_OPTIONS,
  SPV_TYPE_OPTIONS,
  SPV_CLASS_OPTIONS,
  BROKER,
  CLIENT,
  SPV,
  SPV_CLASS,
  SPV_INVESTMENT,
  CONNECTION,
  DOCUMENT_TYPE_OPTIONS_MAP,
};
