import { BrowserRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";
import styled from "styled-components";
import { PageSection, YukaThemeProvider, FontColors } from "yuka";

import { QueryClientProvider } from "api";
import { Unauthorized, NotFound } from "templates/ErrorPages";
import StateProvider from "utils/StateProvider";
import ClientProfileView from "ExecutionApp/ClientProfileView";
import ToastList from "templates/ToastList";

import { REVIEW_FILTER_NAME, APPROVED_FILTER_NAME } from "./constants";
import ComplianceConnectionProfileView from "./ComplianceConnectionProfileView";
import ComplianceConnectionsTableView from "./ComplianceConnectionsTableView";
import ComplianceClientTableView from "./ComplianceClientTableView";
import ComplianceTasksTableView from "./ComplianceTasksTableView";
import Navigation from "./Navigation";

const Layout = styled(PageSection)`
  ${FontColors.theme80};
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
`;

const NavLayout = () => (
  <Layout>
    <Navigation />
    <StyledMain>
      <Outlet />
    </StyledMain>
  </Layout>
);

const ExecutionApp = () => (
  <YukaThemeProvider theme={{ componentVersion: 2, useTangeloTable: false }}>
    <QueryClientProvider>
      <StateProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NavLayout />}>
              <Route index element={<Navigate to="connections/review" replace />} />
              <Route path="connections/review/">
                <Route
                  index
                  element={
                    <ComplianceConnectionsTableView
                      filterName={REVIEW_FILTER_NAME}
                      title="Deal Files Ready for Review"
                    />
                  }
                />
                <Route path=":id/" element={<ComplianceConnectionProfileView />} />
              </Route>
              <Route index element={<Navigate to="connections/approved" replace />} />
              <Route path="connections/approved/">
                <Route
                  index
                  element={
                    <ComplianceConnectionsTableView
                      filterName={APPROVED_FILTER_NAME}
                      title="Approved Deal Files"
                    />
                  }
                />
                <Route path=":id/" element={<ComplianceConnectionProfileView />} />
              </Route>
              <Route path="clients/">
                <Route index element={<ComplianceClientTableView />} />
                <Route path=":id/" element={<ClientProfileView />} />
              </Route>
              <Route path="expiringDocuments/">
                <Route index element={<ComplianceTasksTableView />} />
              </Route>
              <Route path="401/" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastList />
      </StateProvider>
    </QueryClientProvider>
  </YukaThemeProvider>
);

export default ExecutionApp;
