import { Card, CardStyles, Table } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";

import { useClientTableConfig, columns } from "ExecutionApp/ClientTableView/tableConfig";
import RelationshipsContext from "utils/RelationshipsContext";
import { KYC_REVIEW, KYC_INFORMATION_REQUESTED, KYC_INFORMATION_UPDATED } from "utils/constants";

const ComplianceClientTableView = () => {
  const { onRowClick, sortState, sortQueryParam, onSort } = useClientTableConfig();

  const queryParams = {
    sort: sortQueryParam,
    "page[size]": 50,
    "filter[kycStatus]": [KYC_REVIEW, KYC_INFORMATION_REQUESTED, KYC_INFORMATION_UPDATED],
    include: "source,zxRepresentatives",
  };

  const { isLoading, data, fetchNextPage, isFetchingNextPage, relationships } = useInfiniteFetch(
    QUERY_KEYS.CLIENT_PROFILES.list({ ...queryParams })
  );

  return (
    <Card title="People Ready for Review" cardStyle={CardStyles.SECTIONED}>
      <RelationshipsContext.Provider value={relationships}>
        <Table
          usePercentageColumnWidths
          manualSortBy
          sortState={sortState}
          onSort={onSort}
          paginationFunc={fetchNextPage}
          isPaginationLoading={isFetchingNextPage}
          onRowClick={onRowClick}
          isLoading={isLoading}
          data={data || []}
          columns={columns}
        />
      </RelationshipsContext.Provider>
    </Card>
  );
};

export default ComplianceClientTableView;
