import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ColorPalette, Definition, HyperLink, ShieldCheckIcon, ShieldIcon, useTooltip } from "yuka";

import { ZXLink } from "utils/routing";

const StyledValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

/**
 * Renders a single participant linked to relevant profiles as much as possible
 *
 * @param {object} props
 * @returns {React.Element}
 */
const SourceDefinition = ({ source, sourceRole }) => {
  const kycRef = React.useRef(null);
  const {
    isKycApproved,
    individualName,
    individualZxUrl,
    entityName,
    entityZxUrl,
    individual,
    entity,
  } = source;

  const individualContent = React.useMemo(
    () =>
      individualZxUrl ? (
        <ZXLink component={HyperLink} url={individualZxUrl} profileType="user">
          {individualName}
        </ZXLink>
      ) : (
        individualName
      ),
    [individualName, individualZxUrl]
  );

  const entityContent = React.useMemo(
    () =>
      entityZxUrl ? (
        <ZXLink component={HyperLink} url={entityZxUrl} profileType="group">
          {entityName}
        </ZXLink>
      ) : (
        entityName
      ),
    [entityName, entityZxUrl]
  );

  const participantElement = React.useMemo(() => {
    if (individual) {
      return entity ? (
        <>
          {individualContent}
          <span> @ </span>
          {entityContent}
        </>
      ) : (
        individualContent
      );
    }
    if (entity) {
      return entityContent;
    }
  }, [entity, entityContent, individual, individualContent]);

  const kycTooltip = useTooltip(
    kycRef,
    isKycApproved ? "KYC Approved" : "KYC has not been approved"
  );

  const value = React.useMemo(
    () => (
      <StyledValue>
        <span>{participantElement}</span>
        <span ref={kycRef}>
          {isKycApproved ? (
            <ShieldCheckIcon color={ColorPalette.buy} />
          ) : (
            <ShieldIcon color={ColorPalette.white50} />
          )}
          {kycTooltip}
        </span>
      </StyledValue>
    ),
    [isKycApproved, kycTooltip, participantElement]
  );

  return <Definition label={sourceRole} value={value} />;
};

SourceDefinition.propTypes = {
  source: PropTypes.shape({
    individual: PropTypes.number,
    individualName: PropTypes.string,
    individualZxUrl: PropTypes.string,
    isKycApproved: PropTypes.bool,
    entity: PropTypes.number,
    entityName: PropTypes.string,
    entityZxUrl: PropTypes.string,
    ticketAccess: PropTypes.string.isRequired,
  }),
  sourceRole: PropTypes.string.isRequired,
};

export default SourceDefinition;
