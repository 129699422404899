import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Card, DefinitionListHeader, Fonts } from "yuka";

const ComplianceActionItems = ({ connection }) => (
  <Card
    title="Compliance Action Items"
    tagline={`Requested by ${connection.dealFileReviewRequestedByName} on ${DateTime.fromISO(
      connection.lastDealFileActionOn
    ).toLocaleString(DateTime.DATE_MED)}`}
  >
    {connection.complianceNotes && <DefinitionListHeader title="Previous Compliance Comments" />}
    {connection.complianceNotes && (
      <Fonts.Body1theme80>{connection.complianceNotes}</Fonts.Body1theme80>
    )}
    <DefinitionListHeader title="Notes from Deal Lead" />
    <Fonts.Body1theme80>{connection.dealFileNotes}</Fonts.Body1theme80>
  </Card>
);

ComplianceActionItems.propTypes = {
  connection: PropTypes.shape({
    buy: PropTypes.arrayOf(PropTypes.string),
    complianceNotes: PropTypes.string,
    dealFileNotes: PropTypes.string,
    dealFileReviewRequestedByName: PropTypes.string,
    lastDealFileActionOn: PropTypes.string,
    sourcesToKyc: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ComplianceActionItems;
