import React from "react";
import PropTypes from "prop-types";

import { Definition, DefinitionListHeader } from "yuka";

/**
 * Present the execution representative for a connection
 *
 * @param {object} connection
 *
 * @returns {React.Element}
 */
const ExecutionInfo = ({ connection }) => (
  <>
    <DefinitionListHeader title="Execution Info" />
    <Definition label="Deal Lead" value={connection.executionRepName} />
    <Definition label="Noticed By" value={connection.noticedBy} />
  </>
);

ExecutionInfo.propTypes = {
  connection: PropTypes.shape({
    executionRepName: PropTypes.string,
    noticedBy: PropTypes.string,
  }).isRequired,
};

export default ExecutionInfo;
