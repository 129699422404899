import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";
import {
  YukaThemeProvider,
  Card,
  CardStyles,
  Fonts,
  ComponentGroup,
  HyperLink,
  ColorPalette,
} from "yuka";

import RequestComplianceReviewForm from "forms/RequestComplianceReviewForm";

import DealFileStatusBadge from "./DealFileStatusBadge";

const StyledBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledRerequest = styled(Fonts.Caption2theme50)`
  border-top: 1px solid ${ColorPalette.white15};
  padding-top: 16px;
`;

const DEFAULT_TEXT = "Any feedback from Compliance will be shown here.";

const ConnectionComplianceStatusCard = ({ connection, includeRerequest }) => {
  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false);
  return (
    <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
      {showRequestReviewModal && (
        <RequestComplianceReviewForm
          connection={connection}
          onClose={() => setShowRequestReviewModal(false)}
        />
      )}
      <Card cardStyle={CardStyles.PADDED}>
        <ComponentGroup>
          <StyledBadgeContainer>
            <DealFileStatusBadge connection={connection} />
            <Fonts.Caption2theme50>
              {DateTime.fromISO(connection.lastDealFileActionOn).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </Fonts.Caption2theme50>
          </StyledBadgeContainer>
          {connection.complianceNotes ? (
            includeRerequest && (
              <Fonts.Body1theme80 as="p">{connection.complianceNotes}</Fonts.Body1theme80>
            )
          ) : (
            <Fonts.Body1theme50 as="p">{DEFAULT_TEXT}</Fonts.Body1theme50>
          )}
          {includeRerequest && (
            <StyledRerequest as="p">
              When you have addressed the feedback,&nbsp;
              <HyperLink url="#" onClick={() => setShowRequestReviewModal(true)}>
                Send for Compliance Review
              </HyperLink>
            </StyledRerequest>
          )}
        </ComponentGroup>
      </Card>
    </YukaThemeProvider>
  );
};

ConnectionComplianceStatusCard.propTypes = {
  includeRerequest: PropTypes.bool,
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    apiType: PropTypes.string,
    complianceNotes: PropTypes.string,
    lastDealFileActionOn: PropTypes.string,
  }).isRequired,
};

ConnectionComplianceStatusCard.defaultProps = {
  includeRerequest: false,
};

export default ConnectionComplianceStatusCard;
