import { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash-es";

import { Card, CardStyles, Table } from "yuka";

import { useInfiniteFetch, QUERY_KEYS, getRelatedId } from "api";
import Filter from "forms/Filter";
import { pickBy, isEqual, findKey } from "utils";
import { HALF_SECOND } from "utils/constants";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import RelationshipsContext from "utils/RelationshipsContext";
import { complianceConnectionsColumns as columns } from "ExecutionApp/ConnectionsTableView/TableColumns";

import { ACTIONS, GlobalContext } from "utils/StateProvider";

import { dealFileStatusQueryParams } from "./constants";
import ComplianceConnectionsTableFilters from "./ComplianceConnectionsTableFilters";

const source = "connections table - compliance";

// Fire mixpanel events on filter values changes. Debounced to avoid spam-firing events that
// are triggered on text input change. Requires the eslint-disable because `useCallback` doesn't
// know about `debounce` and its dependencies.
const onChangeFilters = debounce(
  (values, prevValues) => {
    const fieldToEventTypeMap = {
      search: "Company or Trade ID",
      source: "Source or User",
      phase: "Phases",
      assignedToMe: "My Connections",
    };
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter compliance connections table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const ComplianceConnectionsTable = ({ filterName, title }) => {
  const {
    dispatch,
    state: { forms },
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Some click handlers that will be forwarded to the necessary cells.
  const clickTradeId = ({ value: tradeId }) =>
    navigator.clipboard
      .writeText(tradeId)
      .then(() => {
        dispatch({ type: ACTIONS.addToast, message: `Copied "${tradeId}" to clipboard.` });
      })
      .catch(() => {
        dispatch({ type: ACTIONS.addToast, message: "Failed to copy." });
      });

  const navigateToConnectionProfile = ({ source, row: { original: originalData }, event }) => {
    mpTrack("click into connection profile", {
      source,
      tradeId: originalData.tradeId,
      company: getRelatedId(originalData.company),
    });
    const isCtrlKeyHeld = event.ctrlKey || event.metaKey;
    if (isCtrlKeyHeld) {
      const url = `${originalData.tradeId}/`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(`${originalData.tradeId}/`);
    }
  };

  // Remove false-y values from the form so they are converted to `undefined` in the queryParams below.
  const formValues = pickBy(forms[filterName] || {});

  const { isFetching, isLoading, data, fetchNextPage, isFetchingNextPage, relationships } =
    useInfiniteFetch(
      QUERY_KEYS.CONNECTIONS.list({
        sort: "-lastEditedOn",
        "page[size]": 50,
        "filter[dealFileStatus]": dealFileStatusQueryParams[filterName],
        "filter[search]": formValues.search,
        "filter[source]": formValues.source,
      })
    );

  return (
    <Card title={title} cardStyle={CardStyles.SECTIONED}>
      <MixpanelSourceContext.Provider value={source}>
        <Filter
          name={filterName}
          filterComponent={ComplianceConnectionsTableFilters}
          onChange={onChangeFilters}
          isLoading={isFetching}
          initialValues={{}}
        />
        <RelationshipsContext.Provider value={relationships}>
          <Table
            paginationFunc={fetchNextPage}
            isPaginationLoading={isFetchingNextPage}
            isLoading={isLoading}
            data={data || []}
            columns={columns}
            source={source}
            clickTradeId={clickTradeId}
            navigateToConnectionProfile={navigateToConnectionProfile}
          />
        </RelationshipsContext.Provider>
      </MixpanelSourceContext.Provider>
    </Card>
  );
};

ComplianceConnectionsTable.propTypes = {
  filterName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ComplianceConnectionsTable;
